import React, { useState, useEffect } from 'react';
import { Box, Text, Spinner, Alert, AlertIcon, Container } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

export default function JobDetail() {
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const apiUrl = process.env.REACT_APP_API_STRING;

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const response = await fetch(`${apiUrl}/employer/applications`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Başvurular alınırken bir hata oluştu.');
                }

                const data = await response.json();
                setApplications(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchApplications();
    }, [apiUrl]);
    return (
        <div>
            <Helmet>
                <title>İlan Başvuruları</title>
                <meta name="description" content="İlan Başvuruları" />
                <meta name="keywords" content="İlan Başvuruları" />
            </Helmet>

            <Container maxW="container.md" mt={8}>
                <Box borderWidth="1px" borderRadius="lg" padding="4">
                    <Text fontSize="2xl" fontWeight="bold" mb={4}>
                        İlana Yapılan Başvurular
                    </Text>

                    {loading ? (
                        <Spinner />
                    ) : error ? (
                        <Alert status="error" marginTop="4">
                            <AlertIcon />
                            {error}
                        </Alert>
                    ) : applications.length > 0 ? (
                        applications.map((application) => (
                            <Box key={application._id} borderWidth="1px" borderRadius="lg" p={4} mb={4}>
                                <Text fontWeight="bold">İş Başlığı: {application.jobId.title}</Text>
                                <Text>Başvuran: {application.userId.name} {application.userId.surname}</Text>
                                <Text>E-posta: {application.userId.email}</Text>
                                <Text>Telefon: {application.userId.phone}</Text>

                            </Box>
                        ))
                    ) : (
                        <Text>Henüz başvuru yapılmamış.</Text>
                    )}
                </Box>
            </Container>
        </div>
    );
}
