import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, FormControl, FormLabel, Input, Text, Container } from '@chakra-ui/react';



export default function AddJobPost() {
  const [title,setTitle] = useState('')
  const [description,setDescription] = useState('')
  const [location,setLocation] = useState('')
  const [sector,setSector] = useState('')
  const [salary,setSalary] = useState('')
  const [extras,setExtras] = useState('')

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_STRING;



  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiUrl}/addJob`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({title,description,location,sector,salary,extras  }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setSuccess(data.message);
        setError('');
        setTitle('');
        setDescription('');
        setLocation('');
        setSector('');
        setSalary('');
        setExtras('');
        navigate('/isveren'); 
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Kayıt sırasında bir hata oluştu.');
        setSuccess('');
      }
    } catch (error) {
      setError('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.');
      setSuccess('');
    }
  };

  return (
    <div>
      <Helmet>
        <title>İş Ekle</title>
        <meta name="description" content="İş Ekle" />
        <meta name="keywords" content="İş Ekle" />
      </Helmet>
      <Container maxW="container.lg" py={8}>
        <Box className="row justify-content-center">
          <Box className="col-sm-12 col-lg-6">
            <form onSubmit={handleSubmit}>


              <FormControl id="title" isRequired>
                <FormLabel>İlan Başlığı:</FormLabel>
                <Input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormControl>

              <FormControl id="description" isRequired>
                <FormLabel>İlan İçeriği:</FormLabel>
                <Input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>

              <FormControl id="location" isRequired>
                <FormLabel>Yer (Lokasyon):</FormLabel>
                <Input
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </FormControl>

              <FormControl id="sector" isRequired>
                <FormLabel>Sektör:</FormLabel>
                <Input
                  type="text"
                  value={sector}
                  onChange={(e) => setSector(e.target.value)}
                />
              </FormControl>

              <FormControl id="salary">
                <FormLabel>Ücret:</FormLabel>
                <Input
                  type="text"
                  value={salary}
                  onChange={(e) => setSalary(e.target.value)}
                  placeholder='Zorunlu Değil'
                />
              </FormControl>

              <FormControl id="extras">
                <FormLabel>Ekstralar:</FormLabel>
                <Input
                  type="text"
                  value={extras}
                  onChange={(e) => setExtras(e.target.value)}
                  placeholder='Zorunlu Değil'
                />
              </FormControl>

              <Button type="submit" colorScheme="blue" mt={4}>
                Ekle
              </Button>

              {error && <Text color="red.500" mt={2}>{error}</Text>}
              {success && <Text color="green.500" mt={2}>{success}</Text>}


            </form>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
