import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, isAuthenticated, isAdmin }) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (isAdmin) {
    return element;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
