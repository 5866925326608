import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getEmployerData } from './getEmployerData';
import { Box, Heading, Text, Spinner, Alert, AlertIcon, Card, CardHeader, CardBody, CardFooter, SimpleGrid, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function Employer() {

  const [employer, setEmployer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmployerData = async () => {
      try {
        const employerData = await getEmployerData();
        setEmployer(employerData);
      } catch (error) {
        setError('Kullanıcı bilgileri alınamadı.');
      } finally {
        setLoading(false);
      }
    };

    fetchEmployerData();
  }, []);

  return (
    <Box 
      className='employerContainer' 
      textAlign="center" 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      flexDirection="column" 
      minH="100vh" 
      p={4}
    >
      <Helmet>
        <title>Profil</title>
        <meta name="description" content="Profil" />
        <meta name="keywords" content="Profil" />
      </Helmet>

      <Box 
        padding="4" 
        maxW={{ base: "100%", sm: "400px" }} 
        borderWidth="1px" 
        borderRadius="lg" 
        boxShadow="lg" 
        mb={8}
        width="100%"
      >
        <Heading as="h2" size="lg" marginBottom="4">İşveren Bilgileri</Heading>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert status="error" marginTop="4">
            <AlertIcon />
            {error}
          </Alert>
        ) : employer ? (
          <Box marginTop="4">
            <Text fontWeight="bold">Ad: {employer.name}</Text>
            <Text>Email: {employer.email}</Text>
            <Text>Şirket: {employer.company}</Text>
            <Text>Adres: {employer.address || 'Adres bilgisi bulunmuyor.'}</Text>
          </Box>
        ) : (
          <Text marginTop="4">Kullanıcı bilgileri bulunamadı.</Text>
        )}
      </Box>

      <Box className="employerOptionsContainer" width="100%" maxW="1200px">
        <SimpleGrid 
          spacing={4} 
          templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} 
          width="100%"
        >
          <Card className='employerCard'>
            <CardHeader>
              <Heading size='md'>İş İlanı Ekle</Heading>
            </CardHeader>
            <CardBody>
              <Text>Yeni bir iş ilanı yayınlayın.</Text>
            </CardBody>
            <CardFooter>
              <Link to='/isEkle'>
                <Button>Ekle</Button>              
              </Link>
            </CardFooter>
          </Card>

          <Card className='employerCard'>
            <CardHeader>
              <Heading size='md'>İş İlanı Düzenle</Heading>
            </CardHeader>
            <CardBody>
              <Text>Yayınladığınız ilanları düzenleyin.</Text>
            </CardBody>
            <CardFooter>
              <Link to='/isIlanlarim'>
                <Button>Düzenle</Button>              
              </Link>
            </CardFooter>
          </Card>

          <Card className='employerCard'>
            <CardHeader>
              <Heading size='md'>Tüm İş İlanlarım</Heading>
            </CardHeader>
            <CardBody>
              <Text>Tüm iş ilanlarınızı görüntüleyin ve değerlendirin. </Text>
            </CardBody>
            <CardFooter>
              <Link to='/tumIslanlarım'>
                <Button>Görüntüle</Button>              
              </Link>
            </CardFooter>
          </Card>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
