import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/index.esm';
import 'bootstrap/js/index.umd';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ColorModeProvider } from './context/ColorModeContext';

const config = {
  initialColorMode: 'light', 
  useSystemColorMode: false, 
};

const theme = extendTheme({ config });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme}>
    <ColorModeProvider>
      <App />
    </ColorModeProvider>
  </ChakraProvider>
);
