import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Heading,
  Divider,
  useToast,
} from '@chakra-ui/react';

export default function DuyuruDuzenle() {
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_STRING;
  const [announcement, setAnnouncement] = useState({
    announcement: '',
    announcementDetail: '',
    announcement2: '',
    announcementDetail2: '',
    announcement3: '',
    announcementDetail3: '',
  });
  const [announcementImgs, setAnnouncementImgs] = useState([]); 
  const [announcementFile, setAnnouncementFile] = useState(null); 
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await fetch(`${apiUrl}/announcementEdit/${id}`);
        if (response.ok) {
          const data = await response.json();
          setAnnouncement({
            announcement: data.announcement,
            announcementDetail: data.announcementDetail,
            announcement2: data.announcement2,
            announcementDetail2: data.announcementDetail2,
            announcement3: data.announcement3,
            announcementDetail3: data.announcementDetail3,
          });
        } else {
          console.error('Duyuru getirilemedi.');
        }
      } catch (err) {
        console.error('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.', err);
      }
    };

    fetchAnnouncement();
  }, [id, apiUrl]);

  const handleImageChange = (e) => {
    setAnnouncementImgs(Array.from(e.target.files)); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('id', id);
    formData.append('announcement', announcement.announcement);
    formData.append('announcementDetail', announcement.announcementDetail);
    formData.append('announcement2', announcement.announcement2);
    formData.append('announcementDetail2', announcement.announcementDetail2);
    formData.append('announcement3', announcement.announcement3);
    formData.append('announcementDetail3', announcement.announcementDetail3);

    
    announcementImgs.forEach((img) => {
      formData.append('announcementImgs', img);
    });

    if (announcementFile) {
      formData.append('announcementFile', announcementFile);
    }

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiUrl}/announcementEdit`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast({
          title: "Duyuru başarıyla güncellendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate('/haberler');
      } else {
        const errorData = await response.json();
        toast({
          title: errorData.message || 'Duyuru güncellenirken bir hata oluştu.',
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        title: 'Sunucuya bağlanılamadı. Lütfen tekrar deneyin.',
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="container" p={5}>
      <Helmet>
        <title>Duyuru Düzenle</title>
        <meta name="description" content="Duyuru Düzenle" />
        <meta name="keywords" content="Duyuru Düzenle" />
      </Helmet>
      <Heading as="h2" size="lg" mb={5}>Duyuru Düzenle</Heading>
      <form onSubmit={handleSubmit}>
        <FormControl id="announcementImgs" mb={5}>
          <FormLabel>Duyuru Görselleri</FormLabel>
          <Input
            type="file"
            multiple 
            onChange={handleImageChange}
          />
        </FormControl>

        <FormControl id="announcementFile" mb={5}>
          <FormLabel>Duyuru Dosyası (PDF veya Word)</FormLabel>
          <Input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={(e) => setAnnouncementFile(e.target.files[0])}
          />
        </FormControl>

        <Divider mb={5} />

        <FormControl id="announcement" mb={5} isRequired>
          <FormLabel>Duyuru Başlığı</FormLabel>
          <Input
            type="text"
            value={announcement.announcement}
            onChange={(e) =>
              setAnnouncement((prev) => ({ ...prev, announcement: e.target.value }))
            }
          />
        </FormControl>

        <FormControl id="announcementDetail" mb={5} isRequired>
          <FormLabel>Duyuru İçeriği</FormLabel>
          <Textarea
            value={announcement.announcementDetail}
            onChange={(e) =>
              setAnnouncement((prev) => ({
                ...prev,
                announcementDetail: e.target.value,
              }))
            }
          />
        </FormControl>

        <Divider mb={5} />

        <FormControl id="announcement2" mb={5}>
          <FormLabel>Duyuru Başlığı 2</FormLabel>
          <Input
            type="text"
            value={announcement.announcement2}
            onChange={(e) =>
              setAnnouncement((prev) => ({ ...prev, announcement2: e.target.value }))
            }
          />
        </FormControl>

        <FormControl id="announcementDetail2" mb={5}>
          <FormLabel>Duyuru İçeriği 2</FormLabel>
          <Textarea
            value={announcement.announcementDetail2}
            onChange={(e) =>
              setAnnouncement((prev) => ({
                ...prev,
                announcementDetail2: e.target.value,
              }))
            }
          />
        </FormControl>

        <Divider mb={5} />

        <FormControl id="announcement3" mb={5}>
          <FormLabel>Duyuru Başlığı 3</FormLabel>
          <Input
            type="text"
            value={announcement.announcement3}
            onChange={(e) =>
              setAnnouncement((prev) => ({ ...prev, announcement3: e.target.value }))
            }
          />
        </FormControl>

        <FormControl id="announcementDetail3" mb={5}>
          <FormLabel>Duyuru İçeriği 3</FormLabel>
          <Textarea
            value={announcement.announcementDetail3}
            onChange={(e) =>
              setAnnouncement((prev) => ({
                ...prev,
                announcementDetail3: e.target.value,
              }))
            }
          />
        </FormControl>

        <Button type="submit" colorScheme="teal" size="lg" mt={5}>
          Onayla
        </Button>
      </form>
    </Box>
  );
}
