import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_STRING;

export const getEmployerData = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await axios.get(`${apiUrl}/employers`, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    return response.data; 
  } catch (error) {
    console.error('Kullanıcı verileri alınırken hata oluştu:', error);
    throw error; 
  }
};
