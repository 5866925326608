import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Grid,
  Button,
  Flex,
} from '@chakra-ui/react';
import { getUserData } from './getUserData';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function User() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserData();
        console.log('User Sayfası:', userData);
        setUser(userData);
      } catch (error) {
        setError('Kullanıcı bilgileri alınamadı.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Box padding={8} maxW="lg" margin="auto">
      <Helmet>
        <title>Profil</title>
        <meta name="description" content="Profil" />
        <meta name="keywords" content="Profil" />
      </Helmet>
      <Heading as="h2" size="lg" marginBottom={6} textAlign="center">
        Kullanıcı Bilgileri
      </Heading>

      <Box borderWidth="1px" borderRadius="lg" padding={6} boxShadow="lg">
        {loading ? (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Spinner size="xl" />
          </Flex>
        ) : error ? (
          <Alert status="error" marginTop={4}>
            <AlertIcon />
            {error}
          </Alert>
        ) : user ? (
          <Box>
            <Text fontWeight="bold" fontSize="lg" marginBottom={4}>
              Ad: {user.name} {user.surname}
            </Text>
            <Text fontSize="lg" marginBottom={2}>Email: {user.email}</Text>
            <Text fontSize="lg">Adres: {user.address || 'Adres bilgisi bulunmuyor.'}</Text>
          </Box>
        ) : (
          <Text marginTop={4}>Kullanıcı bilgileri bulunamadı.</Text>
        )}
      </Box>

      <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4} marginTop={8}>
        <Link to="/isIlanlari">
          <Button colorScheme="blue" width="100%">
            Tüm İş İlanları
          </Button>
        </Link>
        <Link to="/basvurularim">
          <Button colorScheme="teal" width="100%">
            Başvurularım
          </Button>
        </Link>
      </Grid>
    </Box>
  );
}
