import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode';
export default function IseAlimMain() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      const decodedToken = jwtDecode(token);
      setRole(decodedToken.role);
    }
  }, []);

  return (
    <div className='kariyerContainer'>
      <div className="calisanContainer">
        {role === 'user' ? (
          <Link to='/kullanici'>
            <img src="https://www.pazarlamasyon.com/wp-content/uploads/2018/10/işveren.jpg" alt="" />
            <h5>Kullanıcı</h5>
          </Link>
        ) : (
          <Link to='/kariyerUyelik'>
            <img src="https://www.pazarlamasyon.com/wp-content/uploads/2018/10/işveren.jpg" alt="" />
            <h5>Çalışan</h5>
          </Link>
        )}


      </div>
      <div className="isverenContainer">
        {role === 'employer' ? (
          <Link to='/isveren'>
          <img src="https://cdnuploads.aa.com.tr/uploads/Contents/2017/11/02/thumbs_b_c_46fc22510ccdc4cc4008296cf07d497d.jpg?v=112041" alt="" />
          <h5>İşveren</h5>

        </Link>
        ):(
          <Link to='/isverenKayit'>
          <img src="https://cdnuploads.aa.com.tr/uploads/Contents/2017/11/02/thumbs_b_c_46fc22510ccdc4cc4008296cf07d497d.jpg?v=112041" alt="" />
          <h5>İşveren</h5>

        </Link>
        )}

      </div>

    </div>
  )
}
