import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from '../ExampleCarouselImage';
import { faCommentsDollar, faFileInvoice, faUserTag, faCreditCard, faPoll, faPuzzlePiece, faFileAlt, faInfoCircle, faKiwiBird, faFileVideo, faAnglesRight, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Text, Grid, HStack, VStack, List, ListItem, Icon } from '@chakra-ui/react';
import { useCustomColorMode } from '../../context/ColorModeContext';

export default function Home() {
    const { colorMode } = useCustomColorMode();
    const [announcements, setAnnouncements] = useState([]);
    const apiUrl = process.env.REACT_APP_API_STRING;
    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const response = await fetch(`${apiUrl}/getAnnouncement`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setAnnouncements(data);
                } else {
                    console.error('Duyurular getirilemedi.');
                }
            } catch (err) {
                console.error('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.', err);
            }
        };

        fetchAnnouncements();
    }, [apiUrl]);
    return (
        <div className='container'>
            <Helmet>
                <title>Ardahan Ticaret ve Sanayi Odası</title>
                <meta name="description" content="Ardahan Ticaret ve Sanayi Odası" />
                <meta name="keywords" content="Ardahan Ticaret ve Sanayi Odası" />
            </Helmet>
            <div className='row'>
            <div className='col-lg-12'>
            <Carousel className='mainCarousel'>
                {announcements.slice().reverse().map((announcement, index) => (
                <Carousel.Item key={index}>
                    <Link to={`/announcementDetail/${announcement._id}`}>
                    <ExampleCarouselImage
                        imageUrl={
                        announcement.announcementImgs && announcement.announcementImgs.length > 0
                            ? `${apiUrl}/uploads/${announcement.announcementImgs[0]}`
                            : `${apiUrl}/uploads/${announcement.announcementImg}`
                        }
                        text={announcement.announcement}
                    />
                    </Link>
                </Carousel.Item>
                ))}
            </Carousel>
            </div>

                <Box id='optionBoxs' w="100%" p={4}>
                    <Grid className='optionInner' templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>

                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faCommentsDollar} style={{ color: "#63E6BE", fontSize: "2rem" }} />
                            <Link to="http://uygulama.tobb.net/UyeBilgiSorgulama/fw_uyeBilgiSorgulama.do">
                                Üye Borç Sorgulama
                            </Link>
                        </VStack>

                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faUserTag} style={{ color: "#74C0FC", fontSize: "2rem" }} />
                            <Link to="http://uygulama.tobb.net/UyeBilgiSorgulama/fw_uyeBilgiServisi.do">
                                Üye Bilgisi Sorgulama
                            </Link>
                        </VStack>

                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faFileInvoice} style={{ color: "#ff6251", fontSize: "2rem" }} />
                            <Link href="#">
                                Ticaret Tescil ve Sicil
                            </Link>
                        </VStack>

                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faCreditCard} style={{ color: "#3a88fe", fontSize: "2rem" }} />
                            <Link to="https://uye.tobb.org.tr/hizliaidatodeme.jsp">
                                Online Aidat Ödeme
                            </Link>
                        </VStack>

                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faPoll} style={{ color: "#ff6251", fontSize: "2rem" }} />
                            <Link href="#">
                                Anket ve Formlar
                            </Link>
                        </VStack>

                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faPuzzlePiece} style={{ color: "#63E6BE", fontSize: "2rem" }} />
                            <Link to="/belge.pdf" target="_blank" rel="noopener noreferrer">
                                Stratejik Plan
                            </Link>
                        </VStack>


                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faFileAlt} style={{ color: "#96d35f", fontSize: "2rem" }} />
                            <Link href="#">
                                Raporlar ve Yayınlar
                            </Link>
                        </VStack>

                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#00c7fc", fontSize: "2rem" }} />
                            <Link href="#">
                                Kobi Bilgi Sistemi
                            </Link>
                        </VStack>

                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faKiwiBird} style={{ color: "#96d35f", fontSize: "2rem" }} />
                            <Link href="#">
                                Ardahan TSO Projeler
                            </Link>
                        </VStack>

                        <VStack className='options' spacing={4}>
                            <FontAwesomeIcon icon={faFileVideo} style={{ color: "#ff6251", fontSize: "2rem" }} />
                            <Link href="#">
                                Video Galeri
                            </Link>
                        </VStack>
                    </Grid>
                </Box>

            </div>

            <div className="row">
                <Box className="col-lg-8">
                    <Box className="announcementsContainer" p={4} bg="gray.100" borderRadius="md" boxShadow="lg">
                        <HStack mb={4} alignItems="center">
                            <Text fontSize="lg" fontWeight="bold" style={{
                                  color: colorMode === 'light' ? '#000' : '#000', 
                            }}>
                                <Icon as={FontAwesomeIcon} icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                                Duyurular
                            </Text>
                        </HStack>

                        <Box className="announcements">
                            <List spacing={3}>
                                {announcements.slice().reverse().slice(0, 6).map((announcement, index) => (
                                    <ListItem key={index}>
                                        <HStack spacing={3}>
                                            <Icon as={FontAwesomeIcon} icon={faNewspaper} />
                                            <Link to={`/announcementDetail/${announcement._id}`} color="teal.500" fontWeight="bold">
                                                {announcement.announcement}
                                            </Link>
                                        </HStack>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Box>
                </Box>

                <div className="col-lg-4">
                    <div className="presedentContainer">
                        <div className="innerAnnouncements" style={{
                            backgroundColor: colorMode === 'light' ? '#f0f0f0' : '#1a202c', 
                            color: colorMode === 'light' ? '#000' : '#fff', 
                            borderBottom: colorMode === 'light' ? '#f3ecd3'  :  '#1a202c'
                        }} >
                            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                            <span>Başkanımız</span>
                        </div>
                        <div className="presedent">
                            <img src="/cetin-demirci-baskan.jpg" alt="Baskan" />
                        </div>
                        <div className="presedentTitle">
                            <h5>Çetin Demirci</h5>
                            <span>Yönetim Kurulu Başkanı</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-8">
                    <div className="innerAnnouncements" style={{
                            backgroundColor: colorMode === 'light' ? '#f0f0f0' : '#1a202c', 
                            color: colorMode === 'light' ? '#000' : '#fff', 
                            border: colorMode === 'dark' ? '#1a202c' : 'red'
                        }} >
                        <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                        <span>İşbirliği ve Fırsatlar</span>
                    </div>
                    <div className="opportunity">
                        <Link to="https://www.halkbank.com.tr/21647-ise_devam_destegi___kucuk_isletme_can_suyu_kredisi">
                            <img src="/halkbank-can-suyu-kredisi.png" alt="canSuyuKredisi" />
                        </Link>
                        <Link to="https://tobb.org.tr/TurkiyeSektorMeclisleri/Sayfalar/Trendyol.php">
                            <img src="/trendyol-tobb-isbirligi.jpg" alt="trendyolTobb" />
                        </Link>
                    </div>
                </div>
                <div className="col-lg-4"></div>
            </div>

            <div className="row" style={{ marginTop: '3rem' }}>
                <div className="employmentContainer">
                    <div className="innerAnnouncements" style={{
                            backgroundColor: colorMode === 'light' ? '#f0f0f0' : '#1a202c',
                            color: colorMode === 'light' ? '#000' : '#fff', 
                            border: colorMode === 'dark' ? '#1a202c' : 'red'
                        }} >
                        <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                        <span>İstihdam Seferberliği</span>
                    </div>
                    <div className="employment">
                        <div className="row" style={{ width: '100%' }}>
                            <div className="col-sm-12 col-lg-6">
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/fKYStVyt_sk?si=jqdwKIK5UB9RxPgD" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/LdWLehChMO0?si=wmo0bpiaCsT_D6kI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: '3rem' }}>
                <div className="col-lg-12">
                    <div className="innerAnnouncements" style={{
                            backgroundColor: colorMode === 'light' ? '#f0f0f0' : '#1a202c', 
                            color: colorMode === 'light' ? '#000' : '#fff', 
                            border: colorMode === 'dark' ? '#1a202c' : 'red'
                        }} >
                        <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                        <span>TOBB2B Ticarette Birliğin Gücünü Keşfedin</span>
                    </div>

                    <div className="cardContainer">


                        <Link to="http://www.tobb2b.org.tr/ihracat.php">
                            <div className="card" style={{ width: "18rem" }}>
                                <img src="/tobb2b_satisyapmakistiyorum.jpg" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <p className="card-text">Satış Yapmak İstiyorum</p>
                                </div>
                            </div>
                        </Link>


                        <Link to="http://www.tobb2b.org.tr/ithalat.php">
                            <div className="card" style={{ width: "18rem" }}>
                                <img src="/tobb2b_alimyapmakistiyorum.jpg" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <p className="card-text">Alım Yapmak İstiyorum</p>
                                </div>
                            </div>
                        </Link>


                        <Link to="http://www.tobb2b.org.tr/isbirligi.php">
                            <div className="card" style={{ width: "18rem" }}>
                                <img src="/tobb2b_isbirligiyapmakistiyorum.jpg" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <p className="card-text">İşbirliği Yapmak İstiyorum</p>
                                </div>
                            </div>
                        </Link>



                        <Link to="http://www.tobb2b.org.tr/ihale.php">
                            <div className="card" style={{ width: "18rem" }}>
                                <img src="/tobb2b_ihaleveozellestirmeler.jpg" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <p className="card-text">İhale ve Özelleştirmeler</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                <div className="col-lg-6">
                    <div className="innerAnnouncements" style={{
                            backgroundColor: colorMode === 'light' ? '#f0f0f0' : '#1a202c', 
                            color: colorMode === 'light' ? '#000' : '#fff', 
                            border: colorMode === 'dark' ? '#1a202c' : 'red'
                        }} >
                        <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                        <span>İlişkili Kurumlar</span>
                    </div>
                    <div className="organisation">
                        <div id="carouselExampleIndicators1" className="carousel slide">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="10" aria-label="Slide 11"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="11" aria-label="Slide 12"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="12" aria-label="Slide 13"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="13" aria-label="Slide 14"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="14" aria-label="Slide 15"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="15" aria-label="Slide 16"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="16" aria-label="Slide 17"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="17" aria-label="Slide 18"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="18" aria-label="Slide 19"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="19" aria-label="Slide 20"></button>

                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <Link to='https://www.tse.org.tr'>
                                        <img src="https://files.tse.org.tr//2023/06/TSE.png" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.sgk.gov.tr'>
                                        <img src="https://www.sgk.gov.tr/cdn/v2/common/img/kurumsal%20logo-2.png" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='#'>
                                        <img src="https://i0.wp.com/www.yuksekbilgili.com/wordpress/wp-content/uploads/2020/11/abigem.png?fit=250%2C250&ssl=1" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.ticaret.gov.tr'>
                                        <img src="https://seeklogo.com/images/G/gumruk-ve-ticaret-bakanligi-logo-4E0877BDD9-seeklogo.com.png" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.tarimorman.gov.tr'>
                                        <img src="https://logowik.com/content/uploads/images/980_gidatarimbakanligi.jpg" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.tuik.gov.tr'>
                                        <img src="https://seeklogo.com/images/T/turkiye-istatistik-kurumu-yeni-logo-DD6665118F-seeklogo.com.png" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.etu.edu.tr/tr'>
                                        <img src="https://www.etu.edu.tr/files/logolar/standart_logo/yatay/tr/tobb_etu_yatay_tr.jpg" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.tepav.org.tr/tr/'>
                                        <img src="https://www.tepav.org.tr/upload/tepav-m.jpg" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.kgf.com.tr/index.php/tr/'>
                                        <img src="https://www.rtb.org.tr/uploads/files/347-mmmmmm.jpg" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='http://www.balo.tc'>
                                        <img src="https://www.kirklarelitb.org.tr/upload/yazi/byk-anadolu-lojstk-organzasyonlar-a_7f7f37ad75511fe613052024004316000000.webp" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.gtias.com.tr'>
                                        <img src="https://www.kirklarelitb.org.tr/upload/yazi/gmrk-ve-turzm-letmeler-tcaret-a_a98d424bf17fb10813052024003911000000.webp" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://meybem.org.tr'>
                                        <img src="https://www.rtb.org.tr/uploads/696-tobb-meybem-mesleki-yeterlilik-ve-belgelendirme-hizmetleri-image1.jpg" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.rekabet.gov.tr'>
                                        <img src="https://logowik.com/content/uploads/images/rekabet-kurumu8471.logowik.com.webp" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.iskur.gov.tr'>
                                        <img src="https://sehrivangazetesicom.teimg.com/crop/1280x720/sehrivangazetesi-com/uploads/2023/05/iskurrr.jpg" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.eximbank.gov.tr'>
                                        <img src="https://www.eximbank.gov.tr/Content/images/eximbank-logo.svg" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.tobb.org.tr/TOBBKadinGirisimcilerKurulu/Sayfalar/AnaSayfa.php'>
                                        <img src="https://www.tobb.org.tr/TOBBKadinGirisimcilerKurulu/Resimler/Logolar/yatay-1.png" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.tobb.org.tr/TOBBGencGirisimcilerKurulu/Sayfalar/AnaSayfa.php'>
                                        <img src="https://www.tobb.org.tr/TOBBGencGirisimcilerKurulu/foto/GGKLogo.jpg" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.kosgeb.gov.tr'>
                                        <img src="https://i.pinimg.com/originals/ee/d7/48/eed748bdd0076e70345ec2f4edb9720b.png" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.resmigazete.gov.tr'>
                                        <img src="https://seeklogo.com/images/E/e-devlet-sembol-logo-6F971085F4-seeklogo.com.png" className="d-block w-100" alt="..." />
                                    </Link>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>


                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="innerAnnouncements" style={{
                            backgroundColor: colorMode === 'light' ? '#f0f0f0' : '#1a202c', 
                            color: colorMode === 'light' ? '#000' : '#fff', 
                            border: colorMode === 'dark' ? '#1a202c' : 'red'
                        }} >
                        <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                        <span>Anlaşmalı İş Yerleri</span>
                    </div>
                    <div className="organisation">
                        <div id="carouselExampleIndicators2" className="carousel slide">

                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>

                            </div>

                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <Link to='https://www.evginler.com' target='blank'>
                                        <img src="/evginler.jpg" className="d-block w-100 brandLogo" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://agaradogalveyoresel.com' target='blank'>
                                        <img src="/agara.jpeg" className="d-block w-100 brandLogo" alt="..." />
                                    </Link>
                                </div>

                                <div className="carousel-item">
                                    <Link to='https://www.yanatlisut.com' target='blank'>
                                        <img src="/yanatlı.png" className="d-block w-100 brandLogo" alt="..." />
                                    </Link>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true" style={{ backgroundColor: 'black' }}></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true" style={{ backgroundColor: 'black' }}></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}
