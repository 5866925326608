import React,{useState,useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Box, Text, Container, Spinner, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';


export default function AllJobs() {

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const apiUrl = process.env.REACT_APP_API_STRING;

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch(`${apiUrl}/jobs`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('İş ilanlarını alırken bir hata oluştu.');
                }

                const data = await response.json();
                setJobs(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, [apiUrl]);

    if (loading) return <Spinner />;
    if (error) return <Text color="red.500">{error}</Text>;
  return (
    <div>
      <Container maxW="container.lg" py={8}>
                <Helmet>
                    <title>İşveren Tüm İş İlanları</title>
                    <meta name="description" content="İşveren iş ilanlarını görüntüleyin" />
                </Helmet>
                <Box>
                    {jobs.map((job) => (
                        <Box key={job._id} borderWidth="1px" borderRadius="lg" p={4} mb={4}>
                            <Text fontWeight="bold">{job.title}</Text>
                            <Text>{job.description}</Text>
                            <Text color="gray.600">Yer: {job.location}</Text>
                            <Text color="gray.600" >Sektör: {job.sector}</Text>
                            <Text color="gray.600" >Ücret: {job.salary}</Text>
                            <Button>
                                <Link to='/isDetaylari'>İş Detayları</Link>
                            </Button>
                        </Box>
                    ))}
                </Box>
            </Container>
    </div>
  );
}
