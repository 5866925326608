import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useCustomColorMode } from '../context/ColorModeContext';
import { Box, Button } from '@chakra-ui/react';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

export default function Weather() {
    const [weatherData, setWeatherData] = useState(null);
    const [error, setError] = useState(null);
    const { colorMode, toggleColorMode } = useCustomColorMode();

    const fetchWeatherData = () => {
        fetch("https://api.collectapi.com/weather/getWeather?data.lang=tr&data.city=ardahan", {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'authorization': 'apikey 0AP5Ik88oqfveyOBA06z9H:1wOjZQzddzBJaBPenmEaa4'
            }
        })
            .then(response => response.json())
            .then(data => {
                setWeatherData(data.result[0]);
                setError(null);
            })
            .catch(err => {
                setError('Hava durumu verisi alınamadı.');
                setWeatherData(null);
            });
    };

    useEffect(() => {
        fetchWeatherData();
        const intervalId = setInterval(fetchWeatherData, 60 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='header-top'>
            <div className='weather'>
                {weatherData ? (
                    <div className='weather'>
                        <span>{weatherData.date} {weatherData.day}</span>
                        <span>{weatherData.degree} °C</span>
                        <img className='weatherImg' src={weatherData.icon} alt={weatherData.description} />
                    </div>
                ) : (
                    <p>Veriler yükleniyor...</p>
                )}
            </div>

            <div className='social_media'>
                <div className='social_mediaIcons'>
                      <a href="https://www.facebook.com/groups/ardahantso/" target='blank'>
                    <FontAwesomeIcon className='socialMIcon' icon={faFacebookF} />
                </a>

                <a href="https://www.youtube.com/channel/UCBzXz-elgEnSvNIUEgnE3ag" target='blank'>
                    <FontAwesomeIcon className='socialMIcon' icon={faYoutube} />
                </a>
                </div>
              

                <div>
                    <Box p={4}>
                        <Button
                            onClick={toggleColorMode}
                            leftIcon={colorMode === 'light' ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon icon={faSun} />}
                            colorScheme={colorMode === 'light' ? 'gray' : 'yellow'}
                            bg={colorMode === 'light' ? 'gray.800' : 'yellow.200'}
                            color={colorMode === 'light' ? 'white' : 'black'}
                        >
                            {colorMode === 'light' ? 'Gece Modu' : 'Aydınlık'}
                        </Button>
                    </Box>
                </div>
            </div>

        </div>
    );
}
