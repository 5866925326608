import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, FormControl, FormLabel, Input, Text, Container } from '@chakra-ui/react';

export default function EditJobPost() {
    const { id } = useParams(); // URL'deki id parametresi alınır
    const [jobData, setJobData] = useState({
        title: '',
        description: '',
        location: '',
        sector: '',
        salary: '',
        extras: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_STRING;

    // İş ilanı verilerini almak için useEffect kullanılır
    useEffect(() => {
        const fetchJobData = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiUrl}/editJob?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setJobData({
                        title: data.title || '',
                        description: data.description || '',
                        location: data.location || '',
                        sector: data.sector || '',
                        salary: data.salary || '',
                        extras: data.extras || ''
                    });
                } else {
                    setError('İş ilanı verileri alınamadı.');
                }
            } catch (error) {
                setError('Sunucuya bağlanırken bir hata oluştu.');
            }
        };

        fetchJobData();
    }, [id, apiUrl]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${apiUrl}/editJob`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id, ...jobData }),
            });

            if (response.ok) {
                const data = await response.json();
                setSuccess(data.message);
                setError('');
                navigate('/isveren'); 
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Güncellenirken bir hata oluştu.');
                setSuccess('');
            }
        } catch (error) {
            setError('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.');
            setSuccess('');
        }
    };

    return (
        <div>
            <Helmet>
                <title>İş İlanını Düzenle</title>
                <meta name="description" content="İş ilanını düzenleme sayfası" />
            </Helmet>

            <Container maxW="container.lg" py={8}>
                <Box className="row justify-content-center">
                    <Box className="col-sm-12 col-lg-6">
                        <form onSubmit={handleSubmit}>

                            <FormControl id="title" isRequired>
                                <FormLabel>İlan Başlığı:</FormLabel>
                                <Input
                                    type="text"
                                    value={jobData.title}
                                    onChange={(e) => setJobData({ ...jobData, title: e.target.value })}
                                />
                            </FormControl>

                            <FormControl id="description" isRequired>
                                <FormLabel>Açıklama:</FormLabel>
                                <Input
                                    type="text"
                                    value={jobData.description}
                                    onChange={(e) => setJobData({ ...jobData, description: e.target.value })}
                                />
                            </FormControl>

                            <FormControl id="location" isRequired>
                                <FormLabel>Lokasyon:</FormLabel>
                                <Input
                                    type="text"
                                    value={jobData.location}
                                    onChange={(e) => setJobData({ ...jobData, location: e.target.value })}
                                />
                            </FormControl>

                            <FormControl id="sector" isRequired>
                                <FormLabel>Sektör:</FormLabel>
                                <Input
                                    type="text"
                                    value={jobData.sector}
                                    onChange={(e) => setJobData({ ...jobData, sector: e.target.value })}
                                />
                            </FormControl>

                            <FormControl id="salary">
                                <FormLabel>Maaş:</FormLabel>
                                <Input
                                    type="number"
                                    value={jobData.salary}
                                    onChange={(e) => setJobData({ ...jobData, salary: e.target.value })}
                                />
                            </FormControl>

                            <FormControl id="extras">
                                <FormLabel>Ekstra Bilgiler:</FormLabel>
                                <Input
                                    type="text"
                                    value={jobData.extras}
                                    onChange={(e) => setJobData({ ...jobData, extras: e.target.value })}
                                />
                            </FormControl>

                            <Button type="submit" colorScheme="blue" mt={4}>
                                Güncelle
                            </Button>

                            {error && <Text color="red.500" mt={2}>{error}</Text>}
                            {success && <Text color="green.500" mt={2}>{success}</Text>}
                        </form>
                    </Box>
                </Box>

            </Container>
        </div>
    );
}
