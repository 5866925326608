import React, { createContext, useContext } from 'react';
import { useColorMode } from '@chakra-ui/react';


const ColorModeContext = createContext();

export function ColorModeProvider({ children }) {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <ColorModeContext.Provider value={{ colorMode, toggleColorMode }}>
      {children}
    </ColorModeContext.Provider>
  );
}


export function useCustomColorMode() {
  return useContext(ColorModeContext);
}
