import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { 
  Box, 
  Button, 
  Container, 
  FormControl, 
  FormLabel, 
  Input, 
  Text, 
  VStack, 
  useToast, 
  useColorModeValue  
} from '@chakra-ui/react';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_STRING;
  const toast = useToast();
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const containerBgColor = useColorModeValue('white', 'gray.700'); 
  const textColor = useColorModeValue('black', 'white'); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token); 
        setError('');
        toast({
          title: 'Giriş başarılı.',
          description: 'Hoş geldiniz!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/'); 
        window.location.reload(); 
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Giriş sırasında bir hata oluştu.');
        toast({
          title: 'Giriş Hatası.',
          description: errorData.message || 'Giriş sırasında bir hata oluştu.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err) {
      setError('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.');
      toast({
        title: 'Sunucu Hatası.',
        description: 'Sunucuya bağlanılamadı. Lütfen tekrar deneyin.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg={bgColor} minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
      <Helmet>
        <title>Giriş Yap</title>
        <meta name="description" content="Giriş Yap" />
        <meta name="keywords" content="Giriş Yap" />
      </Helmet>
      <Container maxW="lg" bg={containerBgColor} p={6} borderRadius="md" boxShadow="md">
        <VStack spacing={4} align="stretch">
          <Text fontSize="2xl" fontWeight="bold" textAlign="center" color={textColor}>
            Giriş Yap
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl id="email" isRequired>
              <FormLabel color={textColor}>Mail:</FormLabel>
              <Input 
                type="email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                bg={useColorModeValue('white', 'gray.600')} 
                color={textColor} 
              />
            </FormControl>
            <FormControl id="password" isRequired mt={4}>
              <FormLabel color={textColor}>Şifre:</FormLabel>
              <Input 
                type="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                bg={useColorModeValue('white', 'gray.600')} 
                color={textColor} 
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" mt={4} width="full">
              Giriş Yap
            </Button>
            {error && <Text color="red.500" mt={4}>{error}</Text>}
          </form>
        </VStack>
      </Container>
    </Box>
  );
}
