import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Helmet} from 'react-helmet'
export default function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_STRING;
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(`${apiUrl}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setSuccess(data.message);
        setError('');
        setEmail('');
        setPassword('');
        localStorage.setItem('token', data.token); 
        navigate('/login'); 
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Kayıt sırasında bir hata oluştu.');
        setSuccess('');
      }
    } catch (error) {
      setError('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.');
      setSuccess('');
    }
  };
 
  return (
    <div className='register'>
      <Helmet>
            <title>Kayıt Ol</title>
            <meta name="description" content="Kayıt Ol" />
            <meta name="keywords" content="Kayıt Ol" />
        </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <form  onSubmit={handleSubmit}>
              <label htmlFor="email">Mail:</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />

              <label htmlFor="password">Şifre:</label>
              <input 
                type="password" 
                id="password" 
                name="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />

              <input type="submit" value="Kaydol" />

              {error && <p style={{ color: 'red' }}>{error}</p>}
              {success && <p style={{ color: 'green' }}>{success}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
