import React,{useState,useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Box, Text, Container, Spinner, Button } from '@chakra-ui/react';

export default function IsIlanlari() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const apiUrl = process.env.REACT_APP_API_STRING;
    const [appliedJobs, setAppliedJobs] = useState({});

    // Kullanıcının iş ilanına başvurup başvurmadığını kontrol eden fonksiyon
    const checkIfApplied = async (jobId) => {
        try {
            const response = await fetch(`${apiUrl}/checkApplication/${jobId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            if (!response.ok) {
                throw new Error('Başvuru durumu kontrol edilirken bir hata oluştu.');
            }
    
            const data = await response.json();
            return data.applied; // Başvuru durumu
        } catch (error) {
            console.error(error.message);
            return false; // Hata olduğunda başvuru yapılmamış kabul edilir
        }
    };
    

    const applyForJob = async (jobId) => {
        try {
            const response = await fetch(`${apiUrl}/applyJob`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ jobId })
            });

            if (!response.ok) {
                throw new Error('İş başvurusu sırasında bir hata oluştu.');
            }

            // Başvuru yapıldıktan sonra durumu güncelle
            setAppliedJobs(prev => ({ ...prev, [jobId]: true }));

            alert('Başvuru başarılı!');
        } catch (error) {
            console.error(error.message);
            alert('Başvuru sırasında bir hata oluştu.');
        }
    };

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch(`${apiUrl}/jobs`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
    
                if (!response.ok) {
                    throw new Error('İş ilanlarını alırken bir hata oluştu.');
                }
    
                const data = await response.json();
                setJobs(data);
    
                // Her iş ilanı için başvuru durumu kontrol et
                const appliedStatus = await Promise.all(data.map(async (job) => {
                    const applied = await checkIfApplied(job._id);
                    return { jobId: job._id, applied };
                }));
    
                const appliedJobsMap = appliedStatus.reduce((acc, status) => {
                    acc[status.jobId] = status.applied;
                    return acc;
                }, {});
    
                setAppliedJobs(appliedJobsMap);  // Başvuru durumu kaydedilir
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchJobs();
    }, [apiUrl]);
    

    if (loading) return <Spinner />;
    if (error) return <Text color="red.500">{error}</Text>;

    return (
        <div>
        <Helmet>
            <title>Tüm İş İlanları</title>
            <meta name="description" content="Tüm İş İlanları" />
            <meta name="keywords" content="Tüm İş İlanları" />
        </Helmet>

        <div className="allJobsContainer">
            <Container maxW="container.lg" py={8}>
                <Box>
                    {jobs.map((job) => (
                        <Box key={job._id} borderWidth="1px" borderRadius="lg" p={4} mb={4}>
                            <Text fontWeight="bold">{job.title}</Text>
                            <Text>{job.description}</Text>
                            <Text color="gray.600">Yer: {job.location}</Text>
                            <Text color="gray.600">Sektör: {job.sector}</Text>
                            <Text color="gray.600">Ücret: {job.salary}</Text>

                            {/* Başvuru Butonu */}
                            {appliedJobs[job._id] ? (
                                <Button mt={4} colorScheme="blue" isDisabled>
                                    Başvuruldu
                                </Button>
                            ) : (
                                <Button 
                                    mt={4} 
                                    colorScheme="blue" 
                                    onClick={() => applyForJob(job._id)}
                                >
                                    Başvur
                                </Button>
                            )}
                        </Box>
                    ))}
                </Box>
            </Container>
        </div>
    </div>
    );
}
