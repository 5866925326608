import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Image, Heading, Grid, GridItem, useToast } from '@chakra-ui/react';
import Carousel from 'react-bootstrap/Carousel';
import {jwtDecode} from 'jwt-decode';

export default function Haberler() {
  const [announcements, setAnnouncements] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const apiUrl = process.env.REACT_APP_API_STRING;
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await fetch(`${apiUrl}/getAnnouncement`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAnnouncements(data);
        } else {
          console.error('Duyurular getirilemedi.');
        }
      } catch (err) {
        console.error('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.', err);
      }
    };

    const checkAdminStatus = () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.role === 'admin') {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
    };

    fetchAnnouncements();
    checkAdminStatus();
  }, [apiUrl]);

  const deleteAnnouncement = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/deleteAnnouncement`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ announcementid: id }),
      });

      if (response.ok) {
        setAnnouncements((prevAnnouncements) =>
          prevAnnouncements.filter((announcement) => announcement._id !== id)
        );

        toast({
          title: 'Silme işlemi başarılı.',
          description: 'Duyuru başarıyla silindi.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        navigate(0);
      } else {
        console.error('Duyuru silinemedi.');
        toast({
          title: 'Silme işlemi başarısız.',
          description: 'Duyuru silinirken bir hata oluştu.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.error('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.', err);
      toast({
        title: 'Sunucuya bağlanılamadı.',
        description: 'Sunucuya bağlanılamadı. Lütfen tekrar deneyin.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="container">
      <Helmet>
        <title>Haberler</title>
        <meta name="description" content="Haberler" />
        <meta name="keywords" content="Haberler" />
      </Helmet>

      <Grid 
        templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' }} 
        gap={6} 
        id="haberlerContainer"
      >
        {announcements.reverse().map((announcement, index) => (
          <GridItem key={index} maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Link to={`/announcementDetail/${announcement._id}`}>
              {announcement.announcementImgs && announcement.announcementImgs.length > 1 ? (
                <Carousel>
                  {announcement.announcementImgs.map((img, imgIndex) => (
                    <Carousel.Item key={imgIndex}>
                      <Image
                        src={`${apiUrl}/uploads/${img}`}
                        alt={`Duyuru Görseli ${imgIndex + 1}`}
                        maxHeight="100%"
                        maxWidth="100%"
                        objectFit="contain"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <Image
                  src={`${apiUrl}/uploads/${announcement.announcementImgs && announcement.announcementImgs.length === 1
                    ? announcement.announcementImgs[0]
                    : announcement.announcementImg}`}
                  alt={announcement.announcement}
                  maxHeight="100%"
                  maxWidth="100%"
                  objectFit="contain"
                />
              )}
              <Box p="6">
                <Heading size="md" mb={2}>
                  {announcement.announcement}
                </Heading>
              </Box>
            </Link>
            {isAdmin && (
              <Box p="6">
                <Button
                  as={Link}
                  to={`/announcementEdit/${announcement._id}`}
                  colorScheme="blue"
                  mb={2}
                  w="full"
                >
                  Düzenle
                </Button>

                <Button
                  colorScheme="red"
                  onClick={() => deleteAnnouncement(announcement._id)}
                  w="full"
                >
                  Sil
                </Button>
              </Box>
            )}
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
}
