import React, { useState, useEffect } from 'react';
import { Box, Text, Spinner, Alert, AlertIcon, Container } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

export default function UserApplication() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_STRING; 

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await fetch(`${apiUrl}/userApplications`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Başvurular alınamadı.');
        }

        const data = await response.json();
        setApplications(data);
      } catch (error) {
        setError('Başvurular alınırken bir hata oluştu.');
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [apiUrl]);

  return (
    <div>
      <Helmet>
        <title>Başvurularım</title>
        <meta name="description" content="Başvurularım" />
        <meta name="keywords" content="Başvurularım" />
      </Helmet>

      <Container maxW="container.md" mt={8}>
        <Box borderWidth="1px" borderRadius="lg" padding="4">
          <Text fontSize="2xl" fontWeight="bold" mb={4}>
            Yaptığınız Başvurular
          </Text>

          {loading ? (
            <Spinner />
          ) : error ? (
            <Alert status="error" marginTop="4">
              <AlertIcon />
              {error}
            </Alert>
          ) : applications.length > 0 ? (
            applications.map((application) => (
              <Box key={application._id} borderWidth="1px" borderRadius="lg" p={4} mb={4}>
                {application.jobId ? (
                  <>
                    <Text fontWeight="bold">Başvurduğunuz İş: {application.jobId.title}</Text>
                    <Text>Başvuru Tarihi: {new Date(application.appliedAt).toLocaleDateString()}</Text>
                  </>
                ) : (
                  <Text color="red.500">Bu iş ilanı silinmiş veya artık mevcut değil.</Text>
                )}
              </Box>
            ))
          ) : (
            <Text>Henüz bir başvuru yapmadınız.</Text>
          )}
        </Box>
      </Container>
    </div>
  );
}
