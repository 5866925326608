import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { 
  Box, 
  Button, 
  Container, 
  FormControl, 
  FormLabel, 
  Input, 
  Textarea, 
  Text, 
  VStack, 
  useToast, 
  Divider, 
  useColorModeValue 
} from '@chakra-ui/react';

function convertUrlsToLinks(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
}

export default function DuyuruEkle() {
  const [duyuruBaslik1, setDuyuruBaslik1] = useState('');
  const [duyuruBaslik2, setDuyuruBaslik2] = useState('');
  const [duyuruBaslik3, setDuyuruBaslik3] = useState('');

  const [duyuruIcerigi1, setDuyuruIcerigi1] = useState('');
  const [duyuruIcerigi2, setDuyuruIcerigi2] = useState('');
  const [duyuruIcerigi3, setDuyuruIcerigi3] = useState('');

  const [duyuruImgs, setDuyuruImgs] = useState([]);
  const [duyuruDosya, setDuyuruDosya] = useState(null);
  const [message, setMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_STRING;

  const toast = useToast();

  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const containerBgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('black', 'white');
  const placeholderColor = useColorModeValue('gray.500', 'gray.400');

  const handleImageChange = (e) => {
    setDuyuruImgs(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const duyuruHTML1 = convertUrlsToLinks(duyuruIcerigi1);
    const duyuruHTML2 = convertUrlsToLinks(duyuruIcerigi2);
    const duyuruHTML3 = convertUrlsToLinks(duyuruIcerigi3);

    const formData = new FormData();
    formData.append('announcement', duyuruBaslik1);
    formData.append('announcementDetail', duyuruHTML1);
    formData.append('announcement2', duyuruBaslik2);
    formData.append('announcementDetail2', duyuruHTML2);
    formData.append('announcement3', duyuruBaslik3);
    formData.append('announcementDetail3', duyuruHTML3);

    duyuruImgs.forEach((img) => {
      formData.append('announcementImgs', img);
    });

    if (duyuruDosya) {
      formData.append('announcementFile', duyuruDosya);
    }

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiUrl}/announcement`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (response.ok) {
        setMessage('Duyuru başarıyla eklendi.');
        setDuyuruBaslik1('');
        setDuyuruIcerigi1('');
        setDuyuruBaslik2('');
        setDuyuruIcerigi2('');
        setDuyuruBaslik3('');
        setDuyuruIcerigi3('');
        setDuyuruImgs([]);
        setDuyuruDosya(null);

        toast({
          title: 'Başarı!',
          description: 'Duyuru başarıyla eklendi.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || 'Duyuru eklenirken bir hata oluştu.');
        toast({
          title: 'Hata!',
          description: errorData.message || 'Duyuru eklenirken bir hata oluştu.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err) {
      setMessage('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.');
      toast({
        title: 'Sunucu Hatası!',
        description: 'Sunucuya bağlanılamadı. Lütfen tekrar deneyin.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg={bgColor} minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
      <Helmet>
        <title>Duyuru Ekle</title>
        <meta name="description" content="Duyuru Ekle" />
        <meta name="keywords" content="Duyuru Ekle" />
      </Helmet>
      <Container maxW="xl" bg={containerBgColor} p={6} borderRadius="md" boxShadow="md">
        <VStack spacing={4} align="stretch">
          <Text fontSize="2xl" fontWeight="bold" textAlign="center" color={textColor}>
            Duyuru Ekle
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl id="duyuruImgs" mb={4}>
              <FormLabel color={textColor}>Duyuru Görselleri</FormLabel>
              <Input
                type="file"
                onChange={handleImageChange}
                multiple
                bg={containerBgColor}
                color={textColor}
              />
            </FormControl>

            <Divider />

            <FormControl id="duyuruBaslik1" isRequired mb={4}>
              <FormLabel color={textColor}>Duyuru Ana Başlığı</FormLabel>
              <Input
                type="text"
                value={duyuruBaslik1}
                onChange={(e) => setDuyuruBaslik1(e.target.value)}
                bg={containerBgColor}
                color={textColor}
              />
            </FormControl>

            <FormControl id="duyuruIcerigi1" isRequired mb={4}>
              <FormLabel color={textColor}>Duyuru İçeriği 1</FormLabel>
              <Textarea
                value={duyuruIcerigi1}
                onChange={(e) => setDuyuruIcerigi1(e.target.value)}
                bg={containerBgColor}
                color={textColor}
                placeholder="İçeriği buraya yazın"
                _placeholder={{ color: placeholderColor }}
              />
            </FormControl>

            <Divider />

            <FormControl id="duyuruBaslik2" mb={4}>
              <FormLabel color={textColor}>Duyuru Başlığı 2</FormLabel>
              <Input
                type="text"
                value={duyuruBaslik2}
                onChange={(e) => setDuyuruBaslik2(e.target.value)}
                placeholder="Zorunlu Değil"
                bg={containerBgColor}
                color={textColor}
                _placeholder={{ color: placeholderColor }}
              />
            </FormControl>

            <FormControl id="duyuruIcerigi2" mb={4}>
              <FormLabel color={textColor}>Duyuru İçeriği 2</FormLabel>
              <Textarea
                value={duyuruIcerigi2}
                onChange={(e) => setDuyuruIcerigi2(e.target.value)}
                placeholder="Zorunlu Değil"
                bg={containerBgColor}
                color={textColor}
                _placeholder={{ color: placeholderColor }}
              />
            </FormControl>

            <Divider />

            <FormControl id="duyuruBaslik3" mb={4}>
              <FormLabel color={textColor}>Duyuru Başlığı 3</FormLabel>
              <Input
                type="text"
                value={duyuruBaslik3}
                onChange={(e) => setDuyuruBaslik3(e.target.value)}
                placeholder="Zorunlu Değil"
                bg={containerBgColor}
                color={textColor}
                _placeholder={{ color: placeholderColor }}
              />
            </FormControl>

            <FormControl id="duyuruIcerigi3" mb={4}>
              <FormLabel color={textColor}>Duyuru İçeriği 3</FormLabel>
              <Textarea
                value={duyuruIcerigi3}
                onChange={(e) => setDuyuruIcerigi3(e.target.value)}
                placeholder="Zorunlu Değil"
                bg={containerBgColor}
                color={textColor}
                _placeholder={{ color: placeholderColor }}
              />
            </FormControl>

            <FormControl id="duyuruDosya" mb={4}>
              <FormLabel color={textColor}>Duyuru Dosyası (PDF veya Word)</FormLabel>
              <Input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={(e) => setDuyuruDosya(e.target.files[0])}
                bg={containerBgColor}
                color={textColor}
              />
            </FormControl>

            <Button type="submit" colorScheme="blue" width="full">
              Onayla
            </Button>

            {message && <Text color="red.500" mt={4}>{message}</Text>}
          </form>
        </VStack>
      </Container>
    </Box>
  );
}
