import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, FormControl, FormLabel, Input, Text, Container } from '@chakra-ui/react';
export default function EmployerRegister() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [address, setAddress] = useState('');
    const [company, setCompany] = useState('');



    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_STRING;



    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
          const response = await fetch(`${apiUrl}/employerRegister`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password,name,surname,address,company }),
          });
      
          if (response.ok) {
            const data = await response.json();
            setSuccess(data.message);
            setError('');
            setName('');
            setSurname('');
            setAddress('');
            setEmail('');
            setCompany('');
            setPassword('');
            localStorage.setItem('token', data.token); 
            navigate('/login'); 
          } else {
            const errorData = await response.json();
            setError(errorData.message || 'Kayıt sırasında bir hata oluştu.');
            setSuccess('');
          }
        } catch (error) {
          setError('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.');
          setSuccess('');
        }
      };




    return (
        <div>
            <Helmet>
                <title>İşveren Kayıt</title>
                <meta name="description" content="İşveren Kayıt" />
                <meta name="keywords" content="İşveren Kayıt" />
            </Helmet>

            <Container maxW="container.lg" py={8}>
                <Box className="row justify-content-center">
                    <Box className="col-sm-12 col-lg-6">
                        <form onSubmit={handleSubmit}>

                            <FormControl id="name" isRequired>
                                <FormLabel>İsim:</FormLabel>
                                <Input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormControl>

                            <FormControl id="surname" isRequired>
                                <FormLabel>Soyad:</FormLabel>
                                <Input
                                    type="text"
                                    value={surname}
                                    onChange={(e) => setSurname(e.target.value)}
                                />
                            </FormControl>



                            <FormControl id="address" isRequired>
                                <FormLabel>Adres:</FormLabel>
                                <Input
                                    type="address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </FormControl>

                            <FormControl id="company" isRequired>
                                <FormLabel>Şirket:</FormLabel>
                                <Input
                                    type="text"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                            </FormControl>

                            <FormControl id="email" isRequired>
                                <FormLabel>Mail:</FormLabel>
                                <Input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>

                            <FormControl id="password" isRequired>
                                <FormLabel>Şifre:</FormLabel>
                                <Input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>

                            <Button type="submit" colorScheme="blue" mt={4}>
                                Kaydol
                            </Button>

                            {error && <Text color="red.500" mt={2}>{error}</Text>}
                            {success && <Text color="green.500" mt={2}>{success}</Text>}
                        </form>
                    </Box>
                </Box>

            </Container>
        </div>
    );
}
