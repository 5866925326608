import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Text, Container, Spinner, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function EmployerJobs() {

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const apiUrl = process.env.REACT_APP_API_STRING;

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch(`${apiUrl}/jobs`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('İş ilanlarını alırken bir hata oluştu.');
                }

                const data = await response.json();
                setJobs(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, [apiUrl]);


    const deleteJob = async (jobId) => {
        const confirmDelete = window.confirm('Bu ilanı silmek istediğinizden emin misiniz?');
        if (!confirmDelete) return;
    
        try {
            const response = await fetch(`${apiUrl}/deleteJob/${jobId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            if (!response.ok) {
                throw new Error('İş ilanını silerken bir hata oluştu.');
            }
    
            setJobs(jobs.filter(job => job._id !== jobId));
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <Spinner />;
    if (error) return <Text color="red.500">{error}</Text>;

    return (
        <div>
            <Container maxW="container.lg" py={8}>
                <Helmet>
                    <title>İşveren İş İlanları Ayarları</title>
                    <meta name="description" content="İşveren iş ilanlarını görüntüleyin" />
                </Helmet>
                <Box>
                    {jobs.length === 0 ? (
                        <Text>Henüz bir ilan yayınlamadınız.</Text>
                    ) : (
                        jobs.map((job) => (
                            <Box key={job._id} borderWidth="1px" borderRadius="lg" p={4} mb={4}>
                                <Text fontWeight="bold">{job.title}</Text>
                                <Text>{job.description}</Text>
                                <Text color="gray.600">Yer: {job.location}</Text>
                                <Text color="gray.600" >Sektör: {job.sector}</Text>
                                <Text color="gray.600" >Ücret: {job.salary}</Text>
                                <Link to={`/isDuzenle/${job._id}`}>
                                    <Button>Düzenle</Button>
                                </Link>
                                <Button colorScheme="red" onClick={() => deleteJob(job._id)}>Sil</Button>
                            </Box>
                        ))
                    )}
                </Box>
            </Container>
        </div>
    );
}
